import { Box, Checkbox, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CalculationIconComponent from 'components/Icons/CalculationIcon';
import InputIconComponent from 'components/Icons/InputIcon';
import { FORMULA_ERROR } from 'projectConstants';
import {
  getTableCheckBox,
  handleTableCheckBoxChange,
  handleTableCheckBoxChangeAll,
} from 'redux/BuilderModeSlice';

import { AppDispatch } from './GlobalHelpers';

interface IndicatorIconProperties {
  indicatorType: string | undefined;
}

interface TooltopProperties {
  tooltipText: string;
  text: string;
  placeholder: string;
}

interface FormulaErrorBoxProperties {
  hasFormulaError: boolean;
}

interface TableCheckBoxProperties {
  isHeading: boolean;
  itemId: number;
}

export const TooltipContent = ({ tooltipText, text, placeholder }: TooltopProperties) => {
  // Remove only '$' and '%' but keep numbers and decimals
  const cleanedText = text?.replace(/[$%(),.]/g, '').trim();
  // Check if it's a valid number and doesn't contain any alphabets
  const isNumber = /^\d+(\.\d+)?$/.test(cleanedText);
  return (
    <Tooltip label={tooltipText}>
      <p
        style={{
          width: '100%',
          paddingLeft: '.2rem',
          textAlign: isNumber ? 'right' : 'start',
        }}
      >
        {`${text}` || placeholder || ''}
      </p>
    </Tooltip>
  );
};

export const IndicatorIcon = ({ indicatorType }: IndicatorIconProperties) => {
  if ((indicatorType?.toLowerCase() ?? '') === 'calculation') {
    return <CalculationIconComponent color='#4BB6B9' style={{ margin: '0 6px' }} />;
  }
  if ((indicatorType?.toLowerCase() ?? '') === 'input') {
    return <InputIconComponent color='#9C89DD' style={{ margin: '0 6px' }} />;
  }
  return null;
};

export const FormulaErrorBox = ({ hasFormulaError }: FormulaErrorBoxProperties) =>
  hasFormulaError ? (
    <Box ml={4} maxW={'max-content'} color={'red'} fontSize={'2xs'} position={'relative'}>
      <Tooltip label={FORMULA_ERROR} placement={'top'} variant={'primary'}>
        <InfoOutlineIcon height={5} width={5} />
      </Tooltip>
    </Box>
  ) : null;

export const TableCheckBox = ({ isHeading, itemId }: TableCheckBoxProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const tableCheckBox: any = useSelector(getTableCheckBox);

  const allChecked = useMemo(() => {
    return tableCheckBox?.[tableCheckBox?.length && tableCheckBox.length - 2]?.status;
  }, [tableCheckBox?.[tableCheckBox?.length && tableCheckBox.length - 2]?.status]);

  const isIndeterminate = useMemo(() => {
    return tableCheckBox?.[tableCheckBox?.length && tableCheckBox.length - 1]?.status;
  }, [tableCheckBox?.[tableCheckBox?.length && tableCheckBox.length - 1]?.status]);

  const desiredObject = useMemo(
    () => tableCheckBox.find((object: any) => object.id === itemId),
    [tableCheckBox, itemId],
  );

  return (
    <>
      {isHeading ? (
        <Checkbox
          variant={'delete'}
          className='delete-all-checkbox'
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={(event) => {
            dispatch(handleTableCheckBoxChangeAll({ status: event.target.checked }));
          }}
        ></Checkbox>
      ) : (
        <Checkbox
          variant={'delete'}
          isChecked={desiredObject?.status}
          onChange={(event) => {
            dispatch(handleTableCheckBoxChange({ itemId, status: event.target.checked }));
          }}
        ></Checkbox>
      )}
    </>
  );
};
